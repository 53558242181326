import axios from "axios";
import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const defaultState = {
  customers: [],
  customer: {},
  customerLogo: "",
  error: null,
  isPending: null,
  loaded: false,
  msg: "",
};

const url = "Customers";

const EDIT_CUSTOMER = "EDIT_CUSTOMER";
const CREATE_CUSTOMER = "CREATE_CUSTOMER";
const DELETE_CUSTOMER = "DELETE_CUSTOMER";
const LOAD_CUSTOMERS_LIST = "LOAD_CUSTOMERS_LIST";
const GET_CUSTOMER = "GET_CUSTOMER";
const CUSTOMERS_PENDING = "CUSTOMERS_PENDING";
const POST_CUSTOMERS_LOGO = "POST_CUSTOMERS_LOGO";
const CUSTOMERS_ERROR = "CUSTOMERS_ERROR";

export const getCustomers = () => async (dispatch, getState) => {
  dispatch({ type: CUSTOMERS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: LOAD_CUSTOMERS_LIST,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMERS_ERROR,
      error,
    });
  }
};

export const getCustomer = (id) => async (dispatch, getState) => {
  dispatch({ type: CUSTOMERS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${id}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_CUSTOMER,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: CUSTOMERS_ERROR, error });
  }
};

export const editCustomer =
  (id, newCustomerData) => async (dispatch, getState) => {
    dispatch({ type: CUSTOMERS_PENDING });
    try {
      await appBackendAppgainUrl(
        `${url}/${id}`,
        "put",
        newCustomerData,
        getConfigHeaders()
      );
      dispatch({
        type: EDIT_CUSTOMER,
        payload: { id, newCustomerData },
      });
    } catch (error) {
      dispatch({ type: CUSTOMERS_ERROR, error });
    }
  };

export const createCustomer =
  (newCustomerData) => async (dispatch, getState) => {
    dispatch({ type: CUSTOMERS_PENDING });
    try {
      const res = await appBackendAppgainUrl(
        `${url}`,
        "post",
        newCustomerData,
        getConfigHeaders()
      );
      dispatch({
        type: CREATE_CUSTOMER,
        payload: res,
      });
    } catch (error) {
      dispatch({ type: CUSTOMERS_ERROR, error });
    }
  };

export const deleteCustomer = (id) => async (dispatch, getState) => {
  dispatch({ type: CUSTOMERS_PENDING });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());
    dispatch({
      type: DELETE_CUSTOMER,
      payload: id,
    });
  } catch (error) {
    dispatch({ type: CUSTOMERS_ERROR, error });
  }
};

// Logo Upload
export const uploadCustomerLogo =
  (folderName, cdnName, file) => async (dispatch) => {
    // console.log(logo, name,'12');
    dispatch({
      type: CUSTOMERS_PENDING,
    });
    try {
      const data = await axios.post(
        `https://preprodadminapi.appgain.io/aws-cdn.appgain-uploader?folder=${folderName}&bucket=${cdnName}`,
        file
      );
      console.log("data: ", data);
      dispatch({
        type: POST_CUSTOMERS_LOGO,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CUSTOMERS_ERROR,
        error,
      });
    }
  };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_CUSTOMERS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        customers: action.payload,
        loaded: true,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
        loaded: true,
        isPending: false,
      };
    case CUSTOMERS_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: false,
      };

    case CUSTOMERS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        customers: [],
        loaded: true,
      };

    case CREATE_CUSTOMER:
      return {
        ...state,
        isPending: false,
        error: false,
        customers: [...state.customers, action.payload],
        loaded: true,
      };

    case EDIT_CUSTOMER:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        customers: state.customers.map((customer) =>
          customer.objectId == action.payload.id
            ? action.payload.newCustomerData
            : customer
        ),
      };
    case POST_CUSTOMERS_LOGO:
      return {
        ...state,
        customerLogo: action.payload,
        isPending: false,
        error: false,
        loaded: true,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        isPending: false,
        error: false,
        customers: state.customers.filter(
          (customer) => customer.objectId != action.payload
        ),
        loaded: true,
      };
    default:
      return state;
  }
}

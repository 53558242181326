import "./AddEbooks.css";
import { FormControl, FormGroup, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import {
  createEbook,
  editEbook,
  getEbook,
  uploadEbookImage,
} from "../../reducers/Ebooks";

const AddEbook = ({
  history,
  isPending,
  ebook,
  getEbook,
  createEbook,
  editEbook,
  uploadEbookImage,
  ebookImage,
}) => {
  const ebookId =
    window.location.hash.includes("editEbook") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [category, setCategory] = useState("");
  const [featured, setFeatured] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedLogoUrl, setSelectedLogoUrl] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (ebookId) {
      getEbook(ebookId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [ebookId, getEbook]);

  const cancel = () => {
    history.go(-1);
  };

  useEffect(() => {
    if (ebookImage) {
      setSelectedLogoUrl(ebookImage?.data.cdn_url);
    }
  }, [ebookImage]);

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedLogo(e.target.files[0]);
  };

  const submitEbookHandler = (e) => {
    e.preventDefault();
    const ebookData = {
      description: description,
      title: title,
      image: selectedLogoUrl,
      link: link,
      category: category,
      featured: featured,
    };

    if (isEditingMode) {
      editEbook(ebookId, ebookData);
    } else {
      createEbook(ebookData);
    }
    history.push("/ebooks");
  };

  useEffect(() => {
    if (ebook && ebookId) {
      setDescription(ebook.description);
      setLink(ebook.link);
      setCategory(ebook.category);
      setFeatured(ebook.featured);
      setTitle(ebook.title);
      setSelectedLogoUrl(ebook.image);
      setEdit(true);
    }
  }, [ebook, ebookId]);

  const uploadEbookImageHandler = () => {
    const formData = new FormData();
    formData.append("file", selectedLogo);
    uploadEbookImage("website", "cdn.appgain.io", formData);
    setEdit(true);
  };

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <h2 className="title">
        {isEditingMode ? `Edit ebook: ${title}` : "Add New ebook"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitEbookHandler}
      >
        <FormGroup>
          <div className="fieldDiv">
            <label htmlFor="ebookTitle">Title</label>
            <FormControl
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              name="ebookTitle"
              id="ebookTitle"
              placeholder="Enter Title"
              required
            />
          </div>

          <div className="fieldDiv">
            <label htmlFor="eBookDescription">Description</label>
            <FormControl
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              name="eBookDescription"
              id="eBookDescription"
              placeholder="Enter description"
              required
            />
          </div>

          <div className="fieldDiv">
            <label htmlFor="ebookLink">Link</label>
            <FormControl
              type="text"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              name="ebookLink"
              id="ebookLink"
              placeholder="Enter Link"
              required
            />
          </div>
          <div className="fieldDiv">
            <label htmlFor="ebookCategory">Category</label>
            <FormControl
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              name="ebookCategory"
              id="ebookCategory"
              placeholder="Enter the Category of the book"
              required
            />
          </div>
          <div className="fieldDiv featured" title="Featured or not ?">
            <label htmlFor="ebookFeaturedOrNot">Featured</label>
            <FormControl
              type="checkbox"
              checked={featured}
              onChange={(e) => setFeatured(e.target.checked)}
              name="ebookFeaturedOrNot"
              id="ebookFeaturedOrNot"
            />
          </div>
          <div className="fieldDiv">
            <label>{isEditingMode ? "Change" : "Upload"} Photo</label>
            <div className="file-input">
              <input
                type="file"
                accept="image/*"
                onChange={storeImageHandler}
                className="file"
                id="file"
              />
              <label htmlFor="file">
                {selectedLogo || isEditingMode ? "Change " : "Select "} Photo
              </label>
            </div>
            <div style={{ marginTop: "20px" }}>
              {selectedLogo && (
                <React.Fragment>
                  <p>Please Upload the photo</p>
                  <button
                    onClick={uploadEbookImageHandler}
                    className="btn btn-success w-100 my-3"
                  >
                    Upload photo
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </FormGroup>
        <div className="btns">
          <Button
            disabled={!edit}
            className="submitBtn"
            type="submit"
            bsStyle="primary"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        {show && (
          <ConfirmModal
            handleClose={() => setShow(false)}
            handleShow={() => setShow(true)}
            message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
            setShow={setShow}
            show={show}
          ></ConfirmModal>
        )}
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Ebooks.isPending,
  ebookImage: state.Ebooks.ebookLogo,
  ebook: state.Ebooks.ebook,
});

const actions = (dispatch) => ({
  getEbook: (ebookId) => dispatch(getEbook(ebookId)),
  createEbook: (newEbookData) => dispatch(createEbook(newEbookData)),
  editEbook: (ebookId, newEbookData) =>
    dispatch(editEbook(ebookId, newEbookData)),
  uploadEbookImage: (folderName, cdnName, file) =>
    dispatch(uploadEbookImage(folderName, cdnName, file)),
});

export default connect(mapStateToProps, actions)(AddEbook);
